import {
  Caption,
  darkTheme,
  GridContainer,
  GridItem,
  Heading,
  styled,
} from "@eleo/components";
import Image from "next/image";

const sectionDimensionMixin = {
  margin: "0 auto",
  maxWidth: 1200,
};

export const Wrapper = styled("div", {
  padding: "$0 $2 $6 $2",
});

export const Nav = styled("nav", {
  backgroundColor: "$white",
  left: 0,
  padding: "$2",
  position: "sticky",
  top: 0,
  transitionProperty: "padding, box-shadow",
  transitionDuration: "0.2s",
  transitionTimingFunction: "linear",
  zIndex: "$eMax",

  variants: {
    scrolled: {
      true: {
        boxShadow:
          "0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 20px 25px -5px rgba(0, 0, 0," +
          " 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)",
      },
    },
  },
});

export const NavWrapper = styled("div", {
  ...sectionDimensionMixin,
  alignItems: "center",
  gridTemplateColumns: "1fr max-content",
  display: "grid",

  "@bp3a": {
    gridTemplateColumns: "140px 1fr max-content",
  },
});

export const LogoContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  width: "100%",

  img: {
    width: "100px",

    "@bp1a": {
      width: "140px",
    },
  },

  "@bp1a": {
    alignItems: "unset",
    justifyContent: "unset",
    width: "256px",
  },
});

export const Logo = styled(Image, {
  height: "auto",
  width: "140px",

  [`.${darkTheme} &`]: {
    filter: "invert(0.7)",
  },
});

export const NavLinks = styled("ul", {
  display: "none",

  "@bp3a": {
    columnGap: "$2",
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    justifyContent: "center",
    listStyle: "none",
  },
});

export const NavLink = styled("li", {
  a: {
    fontFamily: "$headings",
    fontSize: "16px",
    fontWeight: 600,
    padding: "$1 $3",
  },
});

export const NavCTAContainer = styled("div", {});

export const SectionContainer = styled(GridContainer, {});

export const SectionInnerContainer = styled(GridItem, {
  ...sectionDimensionMixin,
  padding: "$4 $2",
});

export const LandingContainer = styled(SectionContainer, {
  padding: "0 $2",

  "@bp2a": {
    marginBottom: "88px",
    padding: "0 $3",
  },
});

export const LandingContainerInner = styled(GridItem, {
  backgroundColor: "$darkSlate",
  border: "1px solid $greyOnGreyBorderColor",
  borderRadius: "32px",
  paddingTop: "$3",
  paddingBottom: "0",
  textAlign: "center",
  width: "100%",
});

export const SectionHeader = styled(Heading, {
  [`${Wrapper} &`]: {
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "1.25",
    marginBottom: "$1",

    "@bp1a": {
      fontSize: "48px",
    },

    "@bp2a": {
      fontSize: "48px",
    },
  },
});

export const LandingHeadline = styled(SectionHeader, {
  [`${LandingContainerInner} &`]: {
    "@bp2a": {
      fontSize: "72px",
    },
  },
});

export const SectionCaption = styled(Caption, {
  [`${LandingContainerInner} &`]: {
    display: "flex",
    fontFamily: "$headings",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "1.5",
    margin: "0 auto",
    maxWidth: "300px",

    "@bp1a": {
      maxWidth: "600px",
    },
  },
});

export const LandingImg = styled("img", {
  borderRadius: "8px",
  boxShadow:
    "0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 20px 25px -5px rgba(0, 0, 0," +
    " 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)",
  maxWidth: "80%",

  "@bp2a": {
    transform: "scale(1.5)",
  },
});

export const FeatureCardContainer = styled("div", {
  backgroundColor: "$darkSlate",
  borderRadius: "24px",
  height: "200px",
  padding: "$2",
});
export const FeatureCardIconContainer = styled("div", {
  alignItems: "center",
  borderRadius: "50%",
  display: "flex",
  fontSize: "32px",
  height: "64px",
  justifyContent: "center",
  width: "64px",
});
export const FeatureCardIcon = styled("span", {});
export const FeatureCardTitle = styled(Heading, {
  margin: 0,
});
export const FeatureCardDescription = styled("p", {
  fontSize: "14px",
  lineHeight: 1.5,
});

export const FooterLinks = styled("ul", {
  alignItems: "center",
  display: "grid",
  gridTemplateColumns: "repeat(3, max-content)",
  justifyContent: "center",
  listStyle: "none",
  margin: "0 auto",
  padding: "0",
});

export const FooterLink = styled("li", {
  textAlign: "center",

  a: {
    padding: "$2",
  },
});

export const FeatureHeaderContainer = styled("div", {
  alignItems: "center",
  columnGap: "$2",
  display: "grid",
  gridTemplateColumns: "max-content 1fr",
  height: "72px",
});
